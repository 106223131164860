/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { UNISWAP_RINKEBY_URL } from '../../../src/config';
import { web3Modal } from '../../ethereum/web3';
import Web3 from 'web3';
import Balance from '../Balance';
import ConnectButton from '../ConnectButton';
import { getVoyTokenContract } from '../../ethereum/voy_token';

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  UncontrolledAlert,
  Alert,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// import Lottie from 'react-lottie';
import flag from '../../assets/lottie/flag.json';

export default function ColorNavbar(props) {

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: flag,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [navbarColor, setNavbarColor] = React.useState("bg-info");

  React.useEffect(() => {
    onLoad();
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("bg-info");
    }
  };

  const onLoad = async () => {
    console.log('web 3 modal = ', web3Modal);
  }

  const renderChainWarning = () => {
    return (
      <Alert className="alert-with-icon" color="danger">
        <span data-notify="icon" className="fa fa-unlink" />
        <span>
          <b>Wrong Chain!</b> Connect to Fantom Testnet
        </span>
      </Alert>
    )
  }

  const renderConnectWalletButton = () => {
    return (<ConnectButton onClick={props.onConnectClick} />)
  }

  const renderBalances = () => {
    return (
      <div className="d-flex">
        <Balance symbol="FTM" amount={props.ftmBalance}></Balance>
        <Balance symbol="VOY" amount={props.voyBalance}></Balance>
      </div>
    )
  }

  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="https://thevoyage.io/"
              target="_blank"
              id="tooltip6619950104">
              <a
                style={{ display: 'flex' }}
                href="https://thevoyage.io/"
                target="_blank">
                <img style={{ maxHeight: '50px', maxWidth: '80%' }} src={require("assets/img/voyageLogo.png").default}></img>
                <h1 className="text-head text-flame m-auto">VOYAGE</h1>

                {/* <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginLeft: '10px' }}>
                  <Lottie options={lottieOptions}
                    height={30}
                    width={80}
                    isStopped={false}
                    isPaused={false} />

                </div> */}
              </a>
            </NavbarBrand>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a
                    class="text-head text-neautral"
                    href="https://thevoyage.io/"
                    target="_blank">
                    <img style={{ maxHeight: '80px', maxWidth: '100%' }} src={require("assets/img/voyageLogo.png").default}></img>
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Row style={{ width: '100%' }}>
              <Col md="12" className="d-flex justify-content-end">
                {!props.account ?
                  renderConnectWalletButton()
                  :
                  props.chainId !== '0xfa2' ?
                    renderChainWarning()
                    :
                    renderBalances()
                }
              </Col>
            </Row>

          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

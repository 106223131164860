/* global gapi */
/* global google */

import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import axios from 'axios';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1Ijoic2hheW5vcyIsImEiOiJja3V4bDV4OTA1amFsMnB0OXp2aG93OGNuIn0.VZeSu5KU3JFJSOcrB78ezA';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const Map = (props) => {
    const mapContainerRef = useRef(null);

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            // center: [-79.38, 43.65],
            zoom: 1
        });

        map.on('click', function (e) {
            var features = map.queryRenderedFeatures(e.point);
            
            if (features.length && features[0].id) {
                const feature = features[0];
                console.log("feature == ", feature);
                props.onMapClick({ name: feature.properties.name_en, placeId: feature.id })
            }
        });


        // add tooltip when users mouse move over a point
        map.on('mousemove', e => {
            const features = map.queryRenderedFeatures(e.point);
            if (features.length && features[0].id) {
                map.getCanvas().style.cursor = 'pointer';
            } else {
                map.getCanvas().style.cursor = '';
            }
        });

        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={containerStyle}>
            <div className='map-container' style={containerStyle} ref={mapContainerRef} />
        </div>
    );
};


export default Map;


// const center = {
//     lat: -3.745,
//     lng: -38.523
// };

// export default class Map extends Component {

//     getMapOptions = (maps) => {

//         return {
//             streetViewControl: false,
//             scaleControl: true,
//             fullscreenControl: false,
//             styles: [{
//                 featureType: "poi.business",
//                 elementType: "labels",
//                 stylers: [{
//                     visibility: "off"
//                 }]
//             }],
//             gestureHandling: "greedy",
//             disableDoubleClickZoom: true,

//             mapTypeControl: true,
//             mapTypeId: maps.MapTypeId.SATELLITE,
//             mapTypeControlOptions: {
//                 style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
//                 position: maps.ControlPosition.BOTTOM_CENTER,
//                 mapTypeIds: [
//                     maps.MapTypeId.ROADMAP,
//                     maps.MapTypeId.SATELLITE,
//                     maps.MapTypeId.HYBRID
//                 ]
//             },

//             zoomControl: false,
//         };
//     }
//     async onMapClick(values) {
//         if (values.placeId) {
//             var service = new window.google.maps.places.PlacesService(document.getElementById('blah'));
//             service.getDetails({
//                 placeId: values.placeId,
//                 fields: ['name']
//             }, (place, status) => {
//                 this.props.onMapClick({name: place.name, placeId: values.placeId})
//             });
//         }
//     }

//     render() {
//         return (
//             <div style={{width: '100%', height: '100%'}}>
//                 <GoogleMap
//                     id="map"
//                     mapContainerStyle={containerStyle}
//                     center={center}
//                     zoom={3}
//                     onClick={(e) => this.onMapClick(e)}
//                     options={{
//                         streetViewControl: false,
//                         draggable: true, // make map draggable
//                         keyboardShortcuts: false, // disable keyboard shortcuts
//                         scaleControl: true, // allow scale controle
//                         scrollwheel: true, // allow scroll wheel
//                         styles: [{
//                             featureType: "poi",
//                             elementType: "labels",
//                             stylers: [{
//                                 visibility: "off"
//                             }]
//                         }],            
//                     }}

//                 >
//                 </GoogleMap>
//                 <div style={{ width: '0px', height: '0px', display: 'none' }}>
//                 <GoogleMap
//                     style={{ width: '0px', height: '0px', display: 'none' }}
//                     id="blah"
//                     mapContainerStyle={containerStyle}
//                     center={center}
//                     zoom={20}
//                     onClick={(e) => this.onMapClick(e)}
//                     options={{
//                         streetViewControl: false,
//                         draggable: false, // make map draggable
//                         zoomControlOptions: { position: 9 },
//                         keyboardShortcuts: false, // disable keyboard shortcuts
//                         scaleControl: true, // allow scale controle
//                         scrollwheel: true, // allow scroll wheel
//                     }}
//                                 >
//                 </GoogleMap>
//                 </div>
//             </div>
//         )
//     }
// }
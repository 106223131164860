import { Modal, CardHeader, CardTitle, CardBody, CardFooter, Button, Card, Row, Col } from 'reactstrap';
import Countdown from "react-countdown";
import React from 'react';
import moment from 'moment';
// import this.props.web3 from '../ethereum/this.props.web3';
import Loader from "react-loader-spinner";

export default class SubmitAnswer extends React.Component {

    renderApproval() {
        const { answer, submitting, onConfirm, toggleOpen, approvalAmount, approveLoading, onApprove } = this.props;
        return (
            <Card className="card-swap">
                <CardHeader>
                    <CardTitle tag="h4">
                        <div className="icon-big icon-flame" style={{ display: 'flex' }}>
                            <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                <h1 className="text-flame text-head">
                                    <strong>Approval Required</strong></h1>
                            </div>
                        </div>
                    </CardTitle>
                    <button
                        style={{ zIndex: 10000 }}
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleOpen}
                    >
                        <i className="tim-icons icon-simple-remove text-earth" />
                    </button>
                </CardHeader>
                <CardBody>
                    {submitting ?
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Loader
                                type="ThreeDots"
                                color="#01b0c7"
                                height={50}
                                width={50}
                            />
                        </div>
                        :
                        <div>
                            <p className="text-earth text-hl text-center">
                                To submit your answer you need to approve the Voyage Treasure Hunt contract to spend your VOY Tokens.<br></br>
                            </p>
                        </div>
                    }
                </CardBody>
                <CardFooter className="text-center ">
                    {!approveLoading ?
                        <Button
                            block
                            className="btn-round"
                            color="flame"
                            onClick={onApprove}
                            size="lg"
                        >
                            Approve!
                        </Button>

                        :
                        <Loader
                            type="ThreeDots"
                            color="#01b0c7"
                            height={50}
                            width={50}
                        />
                    }
                </CardFooter>
            </Card>
        )
    }

    renderSubmit() {
        const { answer, submitting, onConfirm, toggleOpen } = this.props;
        return (
            <Card className="card-swap">
                <CardHeader>
                    <CardTitle tag="h4">
                        <div className="icon-big icon-flame" style={{ display: 'flex' }}>
                            <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                <h1 className="text-flame text-head">
                                    <strong>Confirm Answer</strong></h1>
                            </div>
                        </div>
                    </CardTitle>
                    <button
                        style={{ zIndex: 10000 }}
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleOpen}
                    >
                        <i className="tim-icons icon-simple-remove text-earth" />
                    </button>
                </CardHeader>
                <CardBody>
                    {submitting ?
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Loader
                                type="ThreeDots"
                                color="#01b0c7"
                                height={50}
                                width={50}
                            />
                        </div>
                        :
                        <div>
                            <p className="text-earth text-hl text-center">
                                You are about to submit the following answer:<br></br>
                                <strong>{answer}</strong>
                            </p>
                            <hr />
                            <p className="text-earth text-hl text-center">
                                500 VOY will be deducted from your wallet if you are incorrect.<br></br>
                            </p>
                            <p className="text-earth text-hl text-center">
                                Are you sure you want to continue?<br></br>
                            </p>
                        </div>
                    }
                </CardBody>
                <CardFooter className="text-center ">
                    {!submitting &&
                        <Button
                            block
                            className="btn-round"
                            color="flame"
                            onClick={onConfirm}
                            size="lg"
                        >
                            Yes!
                        </Button>

                    }
                </CardFooter>
            </Card>
        )
    }

    render() {
        const { answer, submitting, onConfirm, toggleOpen, approvalAmount } = this.props;
        return (
            <Modal isOpen={answer} toggle={toggleOpen} modalClassName="modal-login" backdrop={true} style={{ zIndex: 10000 }}>
                {approvalAmount <= 500 ?
                    this.renderApproval()
                    :
                    this.renderSubmit()
                }
            </Modal>)
    }
}
import { Modal, CardHeader, CardTitle, CardBody, CardFooter, Button, Card, Row, Col } from 'reactstrap';
import Countdown from "react-countdown";
import React from 'react';
import moment from 'moment';
// import this.props.web3 from '../ethereum/this.props.web3';
import Loader from "react-loader-spinner";

export default class IntroCard extends React.Component {
    render() {
        const { isOpen, toggleOpen, title, message, buttonTitle } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={toggleOpen} backdrop={true} style={{ zIndex: 10000 }}>
                <Card className="card-swap">
                    <CardHeader>
                        <CardTitle tag="h4">
                            <div className="icon-big icon-flame" style={{ display: 'flex' }}>
                                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                    <h1 className="text-flame text-head">
                                        <strong>{title}</strong></h1>
                                </div>
                            </div>
                        </CardTitle>
                        <button
                            style={{ zIndex: 10000 }}
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleOpen}
                        >
                            <i className="tim-icons icon-simple-remove text-earth" />
                        </button>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <p className="text-earth text-hl text-center">
                                {message}<br></br>
                            </p>
                        </div>
                    </CardBody>
                    <CardFooter className="text-center ">
                        <Button
                            block
                            className="btn-round"
                            color="flame"
                            onClick={toggleOpen}
                            size="lg"
                        >
                            {buttonTitle}
                        </Button>
                    </CardFooter>
                </Card>
            </Modal>)
    }
}
import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, Col, Row, Container, Button } from 'reactstrap';
import { GasPriceOracle } from 'gas-price-oracle';
import SwapCard from '../components/SwapCard';
import { getGswpstkContract } from '../ethereum/gswpstk';
import { getGswpContract } from '../ethereum/voy_token';
import { getGasSwapContract } from '../ethereum/gasSwap';
import axios from 'axios';
import SwapConfirm from '../components/SwapConfirm';
import { API_URL } from 'config';
import Map from '../components/Map';
import HuntCard from '../components/HuntCard';
import SubmitAnswer from '../components/SubmitAnswer';
import { web3Modal } from 'ethereum/web3';
import Web3 from 'web3';
import { getTreasureHuntFactoryContract } from 'ethereum/treaure_hunt_factory';
import WrongAnswer from 'components/WrongAnswer';
import CorrectAnswer from 'components/CorrectAnswer';
import IntroCard from 'components/IntroCard';
import ColorNavbar from 'components/Navbars/ColorNavbar';
import { getTreasureHuntContract } from 'ethereum/voyage_treasure_hunt';
import { getVoyTokenContract } from '../ethereum/voy_token';
import { VOY_TREASURE_HUNT_ADDRESS } from '../config';
import { ethers } from 'ethers';
import CorrectAudio from '../assets/audio/correct.wav';
import IncorrectAudio from '../assets/audio/incorrect.mp3';

const { MaxUint256 } = ethers.constants;
var web3Util = require('web3-utils');

export default class Main extends React.Component {
  correctAudio = new Audio(CorrectAudio);
  incorrectAudio = new Audio(IncorrectAudio)

  web3;
  treasureHunt;
  token;

  state = {
    playCorrect: false,
    playIncorrect: false,
    answer: '',
    placeId: '',
    account: undefined,
    showWrongAnswer: false,
    web3: null,
    approvalAmount: 0,
    approveLoading: false,
    introCard: {
      open: true,
      title: 'Welcome to Voyage!',
      message: 'Let the hunt begin...',
      buttonTitle: "Let's GO!"
    },
    hunt: {}
  }

  async componentDidMount() {
    const provider = await web3Modal.connect();
    this.web3 = new Web3(provider);
    this.treasureHuntContract = await getTreasureHuntContract(this.web3);
    if (web3Modal.cachedProvider) {
      console.log('cached')
      this.connectWallet();
    }
    this.updateBalances();
    this.getCurrentHunt();
  }

  async playCorrectAudio() {
    const audioPromise = this.correctAudio.play()
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          // autoplay started
        })
        .catch(err => {
          // catch dom exception
          console.info('err ', err)
        })
    }
  }

  async playIncorrectAudio() {
    const audioPromise = this.incorrectAudio.play()
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          // autoplay started
        })
        .catch(err => {
          // catch dom exception
          console.info('err ', err)
        })
    }
  }

  async connectWallet() {
    const provider = await web3Modal.connect();
    this.subscribeToProvider(provider);
    const accounts = await this.web3.eth.getAccounts();
    this.setState({
      ...this.state,
      account: accounts[0],
      chainId: provider.chainId
    })
    this.updateBalances();
    this.subscribeToContractEvents();
    this.checkApprove();
  }

  async subscribeToProvider(provider) {
    provider.on("accountsChanged", (accounts) => {
      console.log('acc = ', accounts)
      this.setState({
        ...this.state,
        account: accounts[0],
      })
      this.updateBalances();
      this.subscribeToContractEvents();
      this.checkApprove();
    });

    provider.on("chainChanged", (chainId) => {
      console.log('chain == ', chainId)
      this.setState({
        ...this.state,
        chainId: chainId
      })

      this.updateBalances();
      this.subscribeToContractEvents();
      this.checkApprove();
    });

    provider.on("connect", (info) => {
      console.log('connect = ', info);
      this.connectWallet();
    });

    provider.on("disconnect", (error) => {
      console.log('disconnect = ', error);
    });
  }

  async getCurrentHunt() {
    const provider = await web3Modal.connect();
    this.web3 = new Web3(provider);
    this.treasureHunt = await getTreasureHuntContract(this.web3);

    // this.subscribeToHunt();
    const data = await this.treasureHunt.methods.activeHuntInfo().call();
    const reward = data[1];
    const activeHunt = data[0];

    console.log("active == ", activeHunt);
    this.setState({
      ...this.state,
      hunt: {
        loading: false,
        collapse: activeHunt.reward === '0',
        isActive: !activeHunt.solved,
        activeHunt: {
          clue: activeHunt.clue,
          reward: this.web3.utils.fromWei(reward, 'ether'),
          expires: new Date(parseInt(activeHunt.expires) * 1000),
          url: activeHunt.url
        }
      }
    })
  }

  async updateBalances() {
    if (!this.state.account || !this.web3) {
      return;
    }
    const balance = await this.web3.eth.getBalance(this.state.account);
    const balanceInEth = await this.web3.utils.fromWei(balance, "ether");
    // setFtmBalance(parseFloat(balanceInEth).toFixed(2));

    const voyBalance = await getVoyTokenContract(this.state.chainId, this.web3).methods.balanceOf(this.state.account).call();
    const voyBalanceInEth = await this.web3.utils.fromWei(voyBalance, "ether");

    console.log("ftm == ", balanceInEth)
    this.setState({
      ...this.state,
      ftmBalance: parseFloat(balanceInEth).toFixed(2),
      voyBalance: parseFloat(voyBalanceInEth).toFixed(2)
    })
    // setVoyBalance(parseFloat(voyBalanceInEth).toFixed(2));
  }

  onToggleAnswerOpen() {
    console.log('toggle')
    this.setState({ answer: '', placeId: '', submitting: false })
  }

  onPlaceClick(place) {
    console.log('got place == ', place)
    this.setState({ answer: place.name, placeId: place.placeId })
  }

  async onConfirmAnswer() {
    this.setState({ submitting: true })
    const accounts = await this.web3.eth.getAccounts();
    const account = await accounts[0];

    try {
      console.log("place == ", this.state.placeId)
      const response = await this.treasureHuntContract.methods.submitAnswer(this.state.placeId.toString()).send({ 
        from: account, 
        value: '100000000000000000'
      });
      this.updateBalances();
      this.getCurrentHunt();
      if (response.events.TreasureHuntSolved) {
        this.playCorrectAudio()
        this.setState({
          submitting: false,
          answer: '',
          placeId: '',
          showWrongAnswer: false,
          showCorrectAnswer: true,
          reward: this.web3.utils.fromWei(response.events.TreasureHuntSolved.returnValues.reward, 'ether')
        })
      } else {
        this.playIncorrectAudio();
        this.setState({
          submitting: false,
          answer: '',
          placeId: '',
          showWrongAnswer: true
        })
      }

      console.log('response == ', response);
    } catch (err) {
      console.log('err == ', err);

      this.setState({
        submitting: false,
        answer: '',
        placeId: '',
      })
    }
  }

  resetIntroState() {
    this.setState({
      ...this.state,
      introCard: {
        title: '',
        message: '',
        buttonTitle: '',
        open: false
      }
    })
  }

  subscribeToContractEvents() {
    this.treasureHunt.events.TreasureHuntStarted().on('data', event => {
      this.onHuntStart();
    })
    this.treasureHunt.events.TreasureHuntSolved().on('data', event => {
      this.onHuntEnd(event);
    })
    this.treasureHunt.events.IncorrectAnswer().on('data', event => {
      this.getCurrentHunt();
    })
  }

  onHuntStart() {
    this.setState({
      ...this.state,
      introCard: {
        title: 'New Treasure Hunt!',
        message: "A new treasure hunt has just started... LETS GO!!!",
        buttonTitle: 'OK',
        open: true
      }
    })

    this.getCurrentHunt();
  }

  onHuntEnd(event) {
    console.log("event == ", event);
    this.playIncorrectAudio();
    this.setState({
      ...this.state,
      introCard: {
        title: 'Treasure Hunt Solved!',
        message: "This hunt has just been solved, better luck next time",
        buttonTitle: 'OK',
        open: true
      }
    })

    this.getCurrentHunt();
  }

  async checkApprove() {
    const approvalAmount = await getVoyTokenContract(this.state.chainId, this.web3).methods.allowance(this.state.account, VOY_TREASURE_HUNT_ADDRESS).call();
    this.setState({
      ...this.state,
      approvalAmount: parseFloat(web3Util.fromWei(approvalAmount, 'ether'))
    })
  }

  async onApprove() {
    this.setState({ approveLoading: true })
    const accounts = await this.web3.eth.getAccounts();

    try {
      await getVoyTokenContract(this.state.chainId, this.web3).methods.approve(VOY_TREASURE_HUNT_ADDRESS, MaxUint256).send({ from: accounts[0] });
      this.setState({ approveLoading: false })
      this.checkApprove();
    } catch (err) {
      this.setState({ approveLoading: false })
    }
  }

  render() {
    const {
      answer,
      placeId,
      submitting,
      showWrongAnswer,
      showCorrectAnswer,
      reward,
      introCard,
      approvalAmount,
      approveLoading
    } = this.state;

    return (
      <div>
        <IntroCard
          isOpen={introCard.open}
          {...introCard}
          toggleOpen={() => this.resetIntroState()}
        />
        <CorrectAnswer reward={reward} isOpen={showCorrectAnswer} toggleOpen={() => this.setState({ showCorrectAnswer: false })} onConfirm={() => this.setState({ showCorrectAnswer: false })} />
        <WrongAnswer isOpen={showWrongAnswer} toggleOpen={() => this.setState({ showWrongAnswer: false })} onConfirm={() => this.setState({ showWrongAnswer: false })} />
        <SubmitAnswer
          answer={answer}
          submitting={submitting}
          approvalAmount={approvalAmount}
          approveLoading={approveLoading}
          toggleOpen={() => this.onToggleAnswerOpen()}
          onConfirm={() => this.onConfirmAnswer()}
          onApprove={() => this.onApprove()}
        />
        <HuntCard {...this.state.hunt} />
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
          <ColorNavbar
            onConnectClick={() => this.connectWallet()}
            {...this.state}
          />
          <div style={{ flex: 2, marginTop: '5.8rem' }}>
            <Map onMapClick={(place) => this.onPlaceClick(place)} />
          </div>
        </div>
      </div>
    )
  }
}


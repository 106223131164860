import React from 'react';
import { Col, Form, FormGroup, Label, Input, Row, Container, Button } from 'reactstrap';
import { web3Modal } from 'ethereum/web3';
import Web3 from 'web3';
import EthCrypto from 'eth-crypto'
import Map from '../components/Map';
import { getTreasureHuntContract } from 'ethereum/voyage_treasure_hunt';

export default class CreateHunt extends React.Component {
    state = {
        reward: 500,
        clue: 'Melbourne Oval',
        placeId: '2103372581',
        url: 'https://s.ndtvimg.com//images/entities/300/melbourne-cricket-ground-26.png'
    }

    async handleSubmit(e) {
        e.preventDefault();
        console.log('form val == ', this.state);

        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        const treasureHunt = await getTreasureHuntContract(web3);
        const message = EthCrypto.hash.keccak256([
            { type: "string", value: this.state.placeId.toString() },
        ]);

        const accounts = await web3.eth.getAccounts();
        const account = await accounts[0];
        const signature = await web3.eth.sign(message, account);

        console.log('sig == ', signature);
        console.log('this.state.clue == ', this.state.clue);
        console.log('this.state.url == ', this.state.url);
        console.log('this.state == ', this.state);


        await treasureHunt.methods.submitTreasureHunt(signature, this.state.clue, this.state.url, web3.utils.toWei(this.state.reward.toString())).send({ from: account });
    }

    onPlaceClick(place) {
        console.log("got place == ", place);
        this.setState({
            ...this.state,
            placeId: place.placeId
        })
    }

    render() {
        var { reward, clue, placeId, url } = this.state;

        return (
            <div>
                <Container>
                    <Row style={{ position: 'absolute', width: '400px', marginTop: '100px', zIndex: '2', padding: '1rem', border: '1px solid white', backgroundColor: 'black' }}>
                        <Col lg="12">
                            <Form onSubmit={(e) => this.handleSubmit(e)}>
                                <FormGroup row>
                                    <Label for="exampleEmail" sm={2} size="lg">Reward</Label>
                                    <Col sm={10}>
                                        <Input value={reward} type="number" name="reward" id="reward" placeholder="lg" bsSize="lg" onChange={(e) => this.setState({ reward: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="exampleEmail2" sm={2}>Clue</Label>
                                    <Col sm={10}>
                                        <Input value={clue} name="clue" id="clue" placeholder="clue" bsSize="lg" onChange={(e) => this.setState({ clue: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="exampleEmail2" sm={2}>Place ID</Label>
                                    <Col sm={10}>
                                        <Input value={placeId} name="placeId" id="placeId" placeholder="placeId" bsSize="lg" onChange={(e) => this.setState({ placeId: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="exampleEmail2" sm={2}>URL</Label>
                                    <Col sm={10}>
                                        <Input value={url} name="url" id="url" placeholder="url" bsSize="lg" onChange={(e) => this.setState({ url: e.target.value })} />
                                    </Col>
                                </FormGroup>
                                <Button >Submit</Button>
                            </Form>
                        </Col>
                    </Row>
                    <div style={{ height: '100vh', width: '100vw' }}>
                        <Map onMapClick={(place) => this.onPlaceClick(place)} />
                    </div>

                </Container>
            </div>
        );
    }
}
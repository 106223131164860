/* global BigInt */

import { Card, CardBody, CardHeader, Col, Row, CardTitle, CardFooter, Input, Nav, NavItem, NavLink, Button, Badge, Container } from 'reactstrap';
import Countdown from "react-countdown";
// import this.props.web3 from '../ethereum/this.props.web3';
import React from 'react';
import classnames from "classnames";
import { VOY_TREASURE_HUNT_ADDRESS } from 'config'
//swaptype 1 = GSWP - GSWPSTK
//swaptype 2 = GSWPSTK - GSWP
import { web3Modal } from 'ethereum/web3';
import Web3 from 'web3';
import { getTreasureHuntFactoryContract } from 'ethereum/treaure_hunt_factory';
import { getTreasureHuntContract } from 'ethereum/voyage_treasure_hunt';

import Loader from "react-loader-spinner";

export default class HuntCard extends React.Component {
    web3;
    treasureHunt;

    state = {
        collapse: false,
        loading: true
    }

    render() {
        const { collapse, isActive, loading, activeHunt } = this.state;
        console.log("PROIPV = ", this.props);
        return (
            <Container>
                <Row style={{ position: 'absolute', width: '100%', marginTop: '100px' }}>
                    <Col lg="3">
                        <Card className="card-hunt" style={{ backgroundColor: 'black', border: '2px solid white', zIndex: 1 }}>
                            <CardHeader>
                                <CardTitle style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Row style={{ width: '100%' }}>
                                        <Col lg="10">
                                            <div className="icon-big icon-flame text-center text-flame" style={{ display: 'flex' }}>
                                                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                                    {this.props.loading ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                            <Loader
                                                                type="ThreeDots"
                                                                color="#01b0c7"
                                                                height={50}
                                                                width={50}
                                                            />
                                                        </div>
                                                        :
                                                        <h1 className="text-flame text-head"><i className="fa fa-gem text-flame" style={{ marginRight: '12px' }} />{this.props.isActive ? 'Treasure Hunt' : 'No Active Hunt'}</h1>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="m-auto">
                                                {this.props.isActive &&
                                                    <i className={collapse ? 'fa fa-arrow-down text-flame' : 'fa fa-arrow-up text-flame'} onClick={() => this.setState({ collapse: !collapse })} style={{ cursor: 'pointer' }} />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            {!collapse && this.props.isActive &&
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <p className="text-white">{this.props.activeHunt.clue}</p>
                                            <hr></hr>
                                            <p className="text-white">Reward: {this.props.activeHunt.reward + ' VOY'}</p>
                                            <hr></hr>
                                            {this.props.activeHunt.url && 
                                                <img src={this.props.activeHunt.url}/>
                                            }
                                            {/* <h1 className="text-white text-head"><Countdown date={activeHunt.expires} /></h1> */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            }

                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

}

// import Web3 from 'web3';
// let web3;
// if (typeof window !== 'undefined' && typeof window.web3 !== 'undefined') {
//   // We are in the browser and metamask is running.
//   web3 = new Web3(window.web3.currentProvider);
// } else {
//   // We are on the server *OR* the user is not running metamask
//   const provider = new Web3.providers.HttpProvider(
//     'https://rinkeby.infura.io/v3/df1da8fda2404e5a97116ddf0caa6191'
//   );
//   web3 = new Web3(provider);
// }

// export default web3;


import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "df1da8fda2404e5a97116ddf0caa6191" // required
    }
  }
};

export const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions
});

import { Modal, CardHeader, CardTitle, CardBody, CardFooter, Button, Card, Row, Col } from 'reactstrap';
import Countdown from "react-countdown";
import React from 'react';
import moment from 'moment';
// import this.props.web3 from '../ethereum/this.props.web3';

export default class ClaimExpired extends React.Component {
    render() {
        const { loading, quote, toggleSwapConfirmOpen, toggleModalOpen, onConfirmSwap } = this.props;
        const expiry = moment(quote.expires);
        console.log('quote == ', quote)
        console.log('expiry === ', expiry);
        return (
            <Modal isOpen={loading || quote} toggle={toggleSwapConfirmOpen} modalClassName="modal-login" backdrop={true}>
                <Card className="card-swap">
                    <CardHeader>
                        <CardTitle tag="h4">
                            <div className="icon-big icon-flame" style={{ display: 'flex' }}>
                                
                                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                    <h1 className="text-flame text-head">
                                        {/* <i className="tim-icons icon-credit-card text-flame" /> */}
                                        Confirm Swap</h1>
                                </div>
                            </div>
                        </CardTitle>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalOpen}
                        >
                            <i className="tim-icons icon-simple-remove text-earth" />
                        </button>
                    </CardHeader>
                    <CardBody>
                        <p className="text-earth text-hl">
                            Please confirm your swap to continue. Your exchange rate may have changed if the gas price has changed.<br></br>
                        In order for your transaction to complete, it must be executed before the timer below runs out. Please adjust your gas limit accordingly.
                    </p>
                        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                            <Col className="text-center">

                                <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem' }}>
                                    Expires:
                            <h3 style={{ marginBottom: 0 }}>
                                        <Countdown date={expiry} />
                                    </h3>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                            <Col className="text-center">

                                <div style={{ display: 'flex', flexDirection: quote.swapType === 1 ? 'column' : 'column-reverse' }}>
                                    <div style={{ background: '#2d1a0f', borderRadius: '1rem', margin: '6px', width: '100%, flex: 1' }}>
                                        <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', textAlign: 'center' }}>
                                            GSWP:
                                            <h3 style={{ marginBottom: 0 }}>{quote.swapType === 2 ? parseFloat(this.props.web3.utils.fromWei(quote.gswpOut, 'ether')).toFixed(3) : parseFloat(this.props.web3.utils.fromWei(quote.gswpIn, 'ether')).toFixed(3)}</h3>
                                        </div>
                                        {quote.reductionPercentage && <div style={{color: 'white'}}>{'minus ' + quote.reductionPercentage + '%'}</div>}
                                    </div>

                                    <div className="icon-big icon-info text-center" style={{ display: 'flex', fontSize: '1.5rem', width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '6px' }}>
                                        <i className="fa fa-arrow-down text-flame"/>
                                    </div>

                                    <div style={{ background: '#2d1a0f', borderRadius: '1rem', margin: '6px', width: '100%, flex: 1' }}>
                                        <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', textAlign: 'center' }}>
                                            GSWPSTK:
                                            <h3 style={{ marginBottom: 0 }}>{quote.swapType === 2 ? parseFloat(this.props.web3.utils.fromWei(quote.gswpstkIn, 'ether')).toFixed(3) : parseFloat(this.props.web3.utils.fromWei(quote.gswpstkOut, 'ether')).toFixed(3)}</h3>
                                        </div>
                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </CardBody>
                    <CardFooter className="text-center">
                        <Button
                            block
                            className="btn-round"
                            color="flame"
                            onClick={(e) => onConfirmSwap()}
                            size="lg"
                        >
                            GO!
                      </Button>
                    </CardFooter>
                </Card>
            </Modal>)
    }
}